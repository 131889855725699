import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'

const HomeOffersList = dynamic(() => import('../components/NltOffers/HomeOffersList'))

interface Props {
  title?: string
  action?: string
  href?: string
  alt?: string
  numPerView?: number
  w?: string | number
  bgColor?: string
}

const HomeNltOffersContainer: FC<Props> = ({ title, href, action, alt, numPerView, w, bgColor }) => {
  const offers = useSelector((state: Store.RootState) => state.nlt.offers)
  const defaultHref = '/noleggio-a-lungo-termine'
  const { t } = useTranslation()

  const offersList = offers.filter(offer => offer.code !== 'auto-promo')

  return (
    <HomeOffersList
      title={title}
      action={action}
      href={href || defaultHref}
      alt={alt || action}
      offers={offersList}
      numPerView={numPerView}
      w={w}
      bgColor={bgColor}
    />
  )
}

export default HomeNltOffersContainer
